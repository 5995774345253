import { useLocation } from 'react-router-dom';
import { MemeIndex } from '../utils/memeIndex';
import { getMemeUrl } from '../utils/storage';
import './Memes.css';
import { useState, useEffect } from 'react';
import sparkles from '../images/sparkles.png';


function Memes() {

  const location = useLocation();

  const [currentMemeIndex, setCurrentMemeIndex] = useState(undefined);
  const [currentMemeUrl, setCurrentMemeUrl] = useState("");
  const [unseenMemeIndexes, setUnseenMemeIndexes] = useState(Object.keys(MemeIndex).map(x => parseInt(x)));

  useEffect(() => {
    getNextMemeIndex();
  }, [location.key]);

  useEffect(() => {
    if (currentMemeIndex !== undefined){
      getMemeUrl(currentMemeIndex)
      .then(url => {
        setCurrentMemeUrl(url);
      })
      .catch(err => {
        console.error(err);
      });
    }
  }, [currentMemeIndex]);

  const getNextMemeIndex = () => {
    let nextUnseenMemeIndex = Math.random() * unseenMemeIndexes.length | 0;
    const memeIndex = unseenMemeIndexes[nextUnseenMemeIndex];
    const newUnseenMemes = unseenMemeIndexes.filter(x => x !== memeIndex);

    if (newUnseenMemes.length === 0){
      setUnseenMemeIndexes(Object.keys(MemeIndex).map(x => parseInt(x)));
    }
    else {
      setUnseenMemeIndexes(newUnseenMemes);
    }

    setCurrentMemeIndex(memeIndex);
  }

  
  return (
    <div className="container memes_container">
        <div className='memes_moreSoupButtonContainer'>
          <div className="memes_moreSoupButton" onClick={getNextMemeIndex}>
          <img src={sparkles} className='memes_sparkles' alt='sparkles' onDragStart={(e) => e.preventDefault()} />
            More Soup
          </div>
        </div>
        <img src={currentMemeUrl} className='memes_img' onDragStart={(e) => e.preventDefault()} />
    </div>
  );
}

export default Memes;

export const MemeIndex = {
    0: '0.jpeg',
    1: '1.jpeg',
    2: '2.jpeg',
    3: '3.jpeg',
    4: '4.jpg',
    5: '5.jpg',
    6: '6.jpeg',
    7: '7.jpeg',
    8: '8.jpeg',
    9: '9.jpeg',
    10: '10.jpeg',
    11: '11.jpeg',
    12: '12.jpeg',
    13: '13.jpeg',
    14: '14.jpeg',
    15: '15.jpeg',
    16: '16.jpeg',
    17: '17.jpeg',
    18: '18.jpeg',
    19: '19.jpeg',
    20: '20.jpeg',
    21: '21.jpeg',
    22: '22.jpeg',
    23: '23.jpeg',
    24: '24.jpeg',
    25: '25.jpeg',
    26: '26.jpeg',
    27: '27.jpeg',
    28: '28.jpg',
    29: '29.jpeg',
    30: '30.jpg',
    31: '31.jpg',
    32: '32.jpeg',
    33: '33.jpg',
    34: '34.jpg',
    35: '35.jpg',
    36: '36.jpg',
    37: '37.jpg',
    38: '38.jpeg',
    39: '39.jpg',
    40: '40.jpg',
    41: '41.jpg',
    42: '42.jpg',
    43: '43.jpeg',
    44: '44.jpg',
    45: '45.jpg',
    46: '46.jpg',
    47: '47.jpg',
    48: '48.jpg',
    49: '49.jpg',
    50: '50.jpg',
    51: '51.jpg',
    52: '52.jpg',
    53: '53.jpg',
    54: '54.jpg',
    55: '55.jpg',
    56: '56.jpg',
    57: '57.jpg',
    58: '58.jpeg',
    59: '59.jpeg',
    60: '60.jpeg',
    61: '61.jpeg',
    62: '62.jpeg',
    63: '63.jpeg',
    64: '64.jpeg',
    65: '65.jpg',
    66: '66.jpeg',
    67: '67.jpeg',
    68: '68.jpeg',
    69: '69.jpeg',
    70: '70.jpeg',
    71: '71.jpeg',
    72: '72.jpeg',
    73: '73.jpeg',
    74: '74.jpeg',
    75: '75.jpeg',
    76: '76.jpg',
    77: '77.jpeg',
    78: '78.jpg',
    79: '79.jpg',
    80: '80.jpeg',
    81: '81.jpeg',
    82: '82.jpeg',
    83: '83.jpeg',
    84: '84.jpeg',
    85: '85.jpg',
    86: '86.jpg',
    87: '87.jpg',
    88: '88.jpeg',
    89: '89.jpeg',
    90: '90.jpeg',
    91: '91.jpg',
    92: '92.jpg',
    93: '93.jpg',
    94: '94.jpg',
    95: '95.jpg',
    96: '96.jpg',
}
import './Body.css';
import { useState, useEffect } from 'react';

function Body({children}) {
  
  return (
    <div className="Body_Container">
        {children}
    </div>
  );
}

export default Body;

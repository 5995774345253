import close from '../images/white-close.png';
import submit from '../images/submit.png';
import soupfestlogo from '../images/soupfest2025logo.png';
import './Login.css';
import { PW } from '../utils/constants';
import { useEffect, useRef, useState } from 'react';
var CryptoJS = require("crypto-js");


const Login = ({ onLogin }) => {

  const [pw, setPW] = useState('');
  const [pwErr, setPWErr] = useState(false);
  const [toasts, setToasts] = useState([]);


  const toastTimerRef = useRef();
  
  useEffect(() => {
    if (toastTimerRef.current)
    {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = undefined;
    }
    if (toasts.length > 0)
    {
      toastTimerRef.current = setTimeout(() => {
        setToasts([]);
      }, 10000);
    }
  }, [toasts]);

  function pwEntered(){
    var hashedpw = CryptoJS.SHA256(pw.toLowerCase()).toString();
    console.log(hashedpw);
    if (hashedpw === PW){
      localStorage.setItem('loggedIn', 'TRUE');
      onLogin(true);
    }
    else{
      onLogin(false);
      setPWErr(true);
    }
  }

  
  function closeToast(){
    setToasts([]);
  }

  return (
    <div className="Login_container">
      <img src={soupfestlogo} className="soup-fest-logo" alt="soup fest 2025"></img>
      <div >
        <p className='label'>Password</p>
        <input className='input' type='text' value={pw} onChange={(e) => {setPWErr(false); setPW(e.target.value)}} ></input>
        <div style={{'height': '80px'}}></div>
        { pwErr && <p style={{'color': 'red', 'position': 'absolute', 'top': '30%', 'width': '100%'}}>nope</p>}
        <img src={submit} className='submitImg' alt='submit' onClick={pwEntered} onDragStart={(e) => e.preventDefault()} />
      </div>
          
      <div style={{'height': '60px'}}></div>
      {
        toasts.length > 0 && 
        <div className='toast'>
          <img src={close} className='close' onClick={closeToast} click />
          {
            toasts.map(toast => 
              <p>{toast}</p>
            )
          }
        </div>
      }
    </div>
  );
}

export default Login;

import { setDoc, getDocs, collection, doc, getDoc, deleteDoc } from "@firebase/firestore"
import { firestore } from "../index"



const resultsCollection = "results";
const adminCollection = "admin";

export const handleSubmit = async (data) => {
    
    
    var ref = doc(firestore, resultsCollection, data.name);
    
    try {
        setDoc(ref, data)
        return null;
    } catch(err) {
        return err;
    }
}

export const docExists = async (docName) => {
    var ref = doc(firestore, resultsCollection, docName);
    var myDoc = await getDoc(ref);
    return myDoc.exists();
}

export const readData = async () => {
    var res = await getDocs(collection(firestore, resultsCollection))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            return newData;
        });
    return res;
}

export const deleteAll = async () => {
    var allDocs = await getDocs(collection(firestore, resultsCollection));
    allDocs.docs.forEach(async (x) => {
        await deleteDoc(x.ref);
    })
}

export const deleteOne = async (id) => {
    var ref = doc(firestore, resultsCollection, id);
    await deleteDoc(ref);
}

export const getResultsVisibleStatus = async () => {
    var ref = doc(firestore, adminCollection, "results_ready");
    var myDoc = await getDoc(ref);
    return myDoc.data().ready;
}

export const setResultsVisibleStatus = async (status) => {
    var ref = doc(firestore, adminCollection, "results_ready");
    await setDoc(ref, {ready: status});
}
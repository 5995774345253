import './NavigationBar.css';
import { useNavigate, useLocation  } from "react-router-dom";

function NavigationBar() {

  const navigate = useNavigate();
  const location = useLocation();



  function handleClick(path) {
    const newKey = new Date().getTime(); // Generate a new key
    navigate(path, { key: newKey });
  }

  
  return (
    <div className="NavigationBar_Container">
        <button type="button" className={`tab-button ${location.pathname == '/' ? 'selected' : ''}`} onClick={() => handleClick("/")}>
          vote
        </button>
        <button type="button" className={`tab-button ${location.pathname == '/results' ? 'selected' : ''}`} onClick={() => handleClick("/results")}>
          results
        </button>
        <button type="button" className={`tab-button ${location.pathname == '/memes' ? 'selected' : ''}`} onClick={() => handleClick("/memes")}>
          memes
        </button>
    </div>
  );
}

export default NavigationBar;

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home/Home';
import Results from './Results/Results';
import AdminMenu from './AdminMenu/AdminMenu';
import { useEffect, useState } from 'react';
import Login from './Login/Login';
import { v4 as uuidv4 } from 'uuid';
import NavigationBar from './NavigationBar/NavigationBar';
import Body from './Body/Body';
import './common.css';
import Memes from "./Memes/Memes";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    var _localID = localStorage.getItem('localID');

    if (!_localID){
      _localID = uuidv4();
      localStorage.setItem('localID', _localID);
    }

    
    setIsLoggedIn(localStorage.getItem('loggedIn') === 'TRUE');
  }, []);

  if (!isLoggedIn)
  { 
    return <Login onLogin={(x) => setIsLoggedIn(x)} />;
  }

  return (
    <BrowserRouter>
      <NavigationBar/>
      <Body>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/results' element={<Results/>} />
          <Route path='/admin' element={<AdminMenu/>} />
          <Route path='/memes' element={<Memes/>} />
        </Routes>
      </Body>
    </BrowserRouter>
  );
}

export default App;

import soup from '../images/soup4.png';
import close from '../images/white-close.png';
import submit from '../images/submit.png';
import './Home.css';
import { useEffect, useRef, useState, FC } from 'react';
import { handleSubmit, docExists, readData } from '../utils/firebase-store';
import { v4 as uuidv4 } from 'uuid';
import { useLongPress } from 'use-long-press';
import { isNumeric } from '../utils/functions';

const Home = ({ }) => {

  const [fav, setFav] = useState('');
  const [fav2, setFav2] = useState('');
  const [fav3, setFav3] = useState('');
  const [toasts, setToasts] = useState([]);

  const [localID, setLocalID] = useState('');
  const [hasVoted, setHasVoted] = useState(false);

  const [resultCount, setResultCount] = useState(1);
  const toastTimerRef = useRef();

  useEffect(() => {
    var _localID = localStorage.getItem('localID');

    if (!_localID){
      _localID = uuidv4();
      localStorage.setItem('localID', _localID);
    }
    setLocalID(_localID);

    docExists(_localID).then(x => {
      setHasVoted(x);
    })
  },[]);

  useEffect(() => {
    if (hasVoted)
    {
      getResultCount();
      setInterval(() => {
        getResultCount();
      }, 3000);
    }
  }, [hasVoted]);

  async function getResultCount(){
    var res = await readData();
    setResultCount(res.length);
  }
  
  useEffect(() => {
    if (toastTimerRef.current)
    {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = undefined;
    }
    if (toasts.length > 0)
    {
      toastTimerRef.current = setTimeout(() => {
        setToasts([]);
      }, 10000);
    }
  }, [toasts]);

  async function submitPressed(){
    var toastMessages = [];
    if (!isNumeric(fav)){
      toastMessages.push('1st favorite is sus');
    }
    else if (fav < 1){
      toastMessages.push('1st favorite is too small');
    }
    else if (fav > 99){
      toastMessages.push('1st favorite is too big');
    }

    if (!isNumeric(fav2)){
      toastMessages.push('2nd favorite is sus');
    }
    else if (fav2 < 1){
      toastMessages.push('2nd favorite is too small');
    }
    else if (fav2 > 99){
      toastMessages.push('2nd favorite is too big');
    }

    if (!isNumeric(fav3)){
      toastMessages.push('3rd favorite is sus');
    }
    else if (fav3 < 1){
      toastMessages.push('3rd favorite is too small');
    }
    else if (fav3 > 99){
      toastMessages.push('3rd favorite is too big');
    }
    
    
    if (((!!fav && !!fav2) && fav == fav2) || ((!!fav && !!fav3) && fav == fav3) || ((!!fav2 && !!fav3) && fav2 == fav3)){
      toastMessages.push('Hey no cheating');
    }

    if (toastMessages.length > 0){
      setToasts(toastMessages);
    }
    else{
      const err = await handleSubmit({name: localID, fav: parseInt(fav), fav2: parseInt(fav2), fav3: parseInt(fav3)});
      if (!err){
        setHasVoted(true);
        localStorage.setItem('hasVoted', true);
      }
      else{
        setToasts([err]);
      }
    }
  }

  const bind = useLongPress(() => {
    localStorage.setItem('hasVoted', false);
    setHasVoted(false);
  });

  function closeToast(){
    setToasts([]);
  }



  return (
    <div className="container">
      {hasVoted && <img src={soup} className="App-logo" alt="logo" />}
      {!hasVoted && <span className='Home_instructionsText'>enter the numbers of your 3 favorite soups!</span>}
        <>
          {
            hasVoted &&
            <>
              <p{...bind()}>
              Thanks for voting!
              </p>
              <span>{resultCount} votes</span>
            </>
          }
          {
            !hasVoted && 
            <>
              <p className='label'>1st favorite</p>
              <input className='input' type='number' value={fav} onChange={(e) => {setFav(e.target.value)}}></input>

              <p className='label'>2nd favorite</p>
              <input className='input' type='number' value={fav2} onChange={(e) => {setFav2(e.target.value)}}></input>

              <p className='label'>3rd favorite</p>
              <input className='input' type='number' value={fav3} onChange={(e) => {setFav3(e.target.value)}}></input>

              <div style={{'height': '80px'}}></div>

              <img src={submit} className='submitImg' alt='submit' onClick={submitPressed} onDragStart={(e) => e.preventDefault()} />
            </>
          }
          {
            toasts.length > 0 && 
            <div className='toast'>
              <img src={close} className='close' onClick={closeToast} click />
              {
                toasts.map(toast => 
                  <p>{toast}</p>
                )
              }
            </div>
          }
        </>
    </div>
  );
}

export default Home;

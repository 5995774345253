import './AdminMenu.css';
import { useState, useEffect, useMemo } from 'react';
import submit from '../images/submit.png';
import { readData, deleteOne, deleteAll, getResultsVisibleStatus, setResultsVisibleStatus } from '../utils/firebase-store';
import { ADMIN_PW } from '../utils/constants';
var CryptoJS = require("crypto-js");

function AdminMenu() {

  const [pw, setPW] = useState('');
  const [hasPW, setHasPW] = useState(false);
  const [results, setResults] = useState([]);
  const [areResultsVisible, setAreResultsVisible] = useState(false);
  
  const votingAction = useMemo(() => areResultsVisible ? 'Hide' : 'Show', [areResultsVisible]);

  useEffect(() => {
    getResults();
    
    getResultsVisibleStatus()
    .then(ready => {
      setAreResultsVisible(ready);
    })
    .catch(err => {
      console.error(err);
    });

    setInterval(() => {
      getResults();
    }, 3000);
  }, []);

  async function deleteAllVotes(){
    await deleteAll();
  } 
  
  function pwEntered(){
    var hashedpw = CryptoJS.SHA256(pw.toLowerCase()).toString();
    if (hashedpw === ADMIN_PW){
      setHasPW(true);
    }
  }

  async function getResults(){
    var res = await readData();
    setResults(res);
  }

  async function deleteVote(id)
  {
    await deleteOne(id);
  }

  const toggleVoting = () => {
    setResultsVisibleStatus(!areResultsVisible)
    .then(() => {
      setAreResultsVisible(!areResultsVisible);
    })
    .catch(err => {
      console.error(err);
    });
  }
  
  return (
    <div className="container">
        <h2 >
          Admin Menu
        </h2>
       <div className='admin-menu_innerContainer'>
       {
          !hasPW &&
          <div style={{'position': 'relative'}}>
            <p>Password</p>
            <input className='input' type='text' value={pw} onChange={(e) => {setPW(e.target.value)}} ></input>
            <div style={{'height': '100px'}}></div>
            <img src={submit} className='submitImg' alt='submit' onClick={pwEntered} onDragStart={(e) => e.preventDefault()} />
          </div>
        }
        {
          hasPW && 
          <>
            <p>{results.length} votes</p>
            {
              results.map((item) => 
                <div className='results_Container' key={item.id}>
                  <span >1st fav <strong>{item.fav}</strong></span>
                  <span >2nd fav <strong>{item.fav2}</strong></span>
                  <span >3rd fav <strong>{item.fav3}</strong></span>
                  <div className='totalContainer'>
                  <input type='button' value='Delete Vote' onClick={() => deleteVote(item.id)} className='button' />
                  </div>
                </div>
              )
            }
            <div className='admin_actionsContainer'>
              <input type='button' value={`${votingAction} results`} onClick={toggleVoting} className='button' />
              <input type='button' value='Delete All Votes' onClick={deleteAllVotes} className='button danger' />
            </div>
          </>
        }
        
       </div>
    </div>
  );
}

export default AdminMenu;
